.App {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

.app-content {
  position: relative;
  background-image: url('./content/mike-scheid-xoYPV4oVQJI-unsplash.jpg');
  background-size: cover;
  background-position: center;
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: whitesmoke;
}

.app-content a {
  color: inherit; /* Or your desired color */
  text-decoration: none; /* Removes underline */
  position: relative; /* Needed for positioning the pseudo-element */
  display: inline-block; /* Ensures that the ::after content is positioned relative to the link */
}

/* Underline on hover */
.app-content a:hover {
  text-decoration: underline; /* Adds underline on hover */
}

.home-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: whitesmoke;
}

.about-me-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: whitesmoke;
}

.experience-content {
  position: relative;
  color: whitesmoke;
  height: 100vh;
  padding: 20px;
}

.experience-divider {
  padding: 10px;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
}

.photo-content {
  width: 200px; /* Adjust the width and height for your photo */
  height: 200px;
  border-radius: 50%; /* Make it circular */
  overflow: hidden; /* Hide any overflowing content */
  position: relative;
  z-index: 2; /* Ensure the image is above the overlay */
  margin-top: -80px !important;
}

.my-photo {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the photo fills the circular container */
  object-position: center; /* Center the image within the circular container */
  border-radius: 50%; /* Make sure the image itself is circular */
}

.main-content,
.sub-content {
  padding: 10px;
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure the text is above the overlay */
}

.navbar-header, .navbar-footer {
  background-color: black;
  width: 100%;
}

.navbar-header a, .navbar-footer a {
  color: whitesmoke;
  font-weight: bolder;
  font-size: larger;
}

.navbar-header a:hover, .navbar-footer a:hover {
  color: #808080;
}

.icon-link {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits the color from its parent, or you can set a specific color */
  font-size: 28px; /* Adjusts the size of the icon. Set it according to your needs */
  padding: 15px;

  color: inherit; /* Or your desired color */
  text-decoration: none; /* Removes underline */
  transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
  opacity: 0.7; /* Slightly dimmed; adjust as needed */
}

.icon-link i {
  vertical-align: middle; /* Adjusts the alignment of the icon with the text, if necessary */
}

.icon-link:hover {
  opacity: 1.0; /* Full opacity (regular brightness) */
}

